.hero {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 72px;
    padding-bottom: 72px;
    background-color: $color-secondary;
    background-image: url(/system/modules/capital-conseil/assets/img/hero.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: $color-text-lightest;
    font-weight: $fw-black;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
    }

    &-title {
        margin-top: auto;
        margin-bottom: auto;
        line-height: 1;
        font-size: 150px;
        color: inherit;
        font-weight: $fw-light;
    }

    &-icon {
        display: block;
        width: 410px;
        max-width: 100%;
        height: auto;
        margin-top: auto;
        margin-bottom: 36px;

        svg {
            max-width: 100%;
        }
    }

    &-caption {
        max-width: 420px;
        margin-bottom: 96px;
        margin-left: auto;
        margin-right: auto;
        font-size: 36px;
        font-weight: $fw-light;

        &::before {
            content: "—";
            display: block;
            color: $color-primary;
        }
    }

    &-scroll-to {
        display: block;
        width: 36px;
        height: 36px;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        transition-property: transform;
        transition-duration: .25s;
        transition-timing-function: ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

        .hero-title + & {
            margin-top: 0;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    @include media-breakpoint-down(lg) {
        &-title {
            font-size: 120px;
        }
    }

    @include media-breakpoint-down(md) {
        &-title {
            font-size: 96px;
        }
    }

    @include media-breakpoint-down(sm) {
        &-title {
            font-size: 64px;
        }
    }

    @include media-breakpoint-down(xs) {
        &-title {
            font-size: 48px;
        }
    }
}
