*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: $color-text;
}

#wrapper {
    overflow: hidden;
}

a {
    color: $color-primary;
}

img,
video {
    max-width: 100%;
    height: auto;
}

textarea {
    max-width: 100%;
}

@include headings() {
    text-align: center;
    font-weight: $fw-bold;
    color: $color-secondary;

    &::after {
        content: "—";
        display: block;
        color: $color-primary;
    }
}

h2,
.h2 {
    margin-top: calc(48px + 1.2em);
    margin-bottom: 48px;

    &:first-child {
        margin-top: 0;
    }
}

.btn {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    padding-right: 32px;
    border-top-left-radius: 0;
    border-top-right-radius: 32px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 0;
    font-weight: $fw-bold;
    transition-property: color, background-color, border-color, box-shadow, transform;

    &:hover {
        transform: scale(1.05);
    }

    &-primary,
    &-secondary,
    &-outline-primary:hover,
    &-outline-secondary:hover {
        color: $color-text-lightest;
    }
}

.hyperlink-wrapper {
    text-align: center;
}

.partners-row {
    @include media-breakpoint-up(lg) {
        .col-lg {
            flex-basis: 20%;
        }
    }
}

.picto-block {
    transition-property: transform;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
}

.leaflet-map-pane {
    z-index: auto;
    filter: grayscale(100%);
}
