.navbar {
    transition-property: transform, background-color;
    transition-duration: .25s;
    transition-timing-function: ease-out;

    &.-shown,
    body.-scrolled & {
        background-color: $color-bg-dark;
    }

    body.-scrolled.-down & {
        transform: translateY(-100%);
    }

    &-brand {
        margin-left: .75rem;
        margin-right: auto;
        font-size: 18px;
        font-weight: $fw-black;
        transition-property: transform;
        transition-duration: .25s;
        transition-timing-function: ease-in-out;

        &:hover {
            transform: scale(1.05);
        }
    }

    &-nav {
        line-height: 1.1;

        .navbar-dark & .nav-link {
            color: $color-text-lightest;
        }
    }

    &-toggler {
        border-width: 0;
        transition-property: filter;
        transition-duration: .25s;
        transition-timing-function: ease-in-out;

        .navbar-dark &:hover {
            filter: brightness(200%);
        }
    }

    &-collapse {
        padding-left: .75rem;
    }

    .nav-link {
        display: inline-block;
    }

    &-contact,
    &-social {
        margin-left: 10px;
        margin-right: 10px;
    }

    &-contact {
        margin-left: auto;

        .block-contact,
        p {
            margin-bottom: 0;
        }

        .phone-number {
            display: inline-block;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 12px;
            padding-right: 12px;
            border-style: solid;
            border-width: 1px;
            border-color: $color-primary;
            white-space: nowrap;
            transition-property: transform, color, background-color;
            transition-duration: .25s;
            transition-timing-function: ease-in-out;

            &:hover {
                transform: scale(1.05);
                background-color: $color-primary;
                color: $color-text-lightest;
                text-decoration: none;
            }
        }
    }

    &-social {
        margin-top: 6px;
        margin-bottom: 6px;

        a {
            display: inline-block;
        }

        svg {
            display: block;
            height: 24px;
            width: 24px;
        }
    }

    @include media-breakpoint-down(xs) {
        .navbar-contact {
            display: none;
        }
    }

    @media (min-width: 1200px) {
        &-brand {
            order: 1;
        }

        &-collapse {
            order: 2;
        }

        &-contact,
        &-social {
            order: 3;
        }

        &-expand-xl &-collapse {
            .navbar-nav {
                align-items: center;
            }

            .nav-item {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    height: 1em;
                    width: 1px;
                    background-color: $color-primary;
                }
            }
        }
    }
}
