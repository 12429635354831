.case-studies {
    &.section {
        padding-top: 0;

        & > .inside {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    h2 {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        text-align: left;

        &::after {
            display: none;
        }
    }
}

.case-study {
    margin-bottom: 24px;

    &-title {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 24px;
        background-color: $color-secondary;
        color: $color-text-lightest;
        font-size: 18px;
        font-weight: $fw-black;
        transition-property: color, background-color;
        transition-duration: .25s;
        transition-timing-function: ease-in-out;

        &.active {
            background-color: $color-primary;
        }

        span {
            &:first-child {
                &::before {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    width: 36px;
                    height: 36px;
                    margin-right: 10px;
                    background-image: url(/system/modules/capital-conseil/assets/svg/chevron-right.svg);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        svg {
            display: inline-block;
            vertical-align: middle;
            flex-shrink: 0;
            width: 36px;
            height: 36px;
            margin-right: 10px;
            fill: $color-primary;
        }
    }

    &-content {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 24px;
        padding-top: 36px;
        padding-bottom: 36px;
        padding-left: 60px;
        padding-right: 60px;
        background-color: $color-primary;
        color: $color-text-lightest;

        &-wrapper {
            position: relative;
            z-index: 1000;
        }

        &-icon {
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 50%;
            border-style: solid;
            border-color: currentColor;
            border-width: 4px;

            svg {
                display: block;
                width: 64px;
                height: 64px;
                fill: currentColor;
            }
        }
    }

    &-text {
        h4 {
            border-bottom-style: solid;
            border-bottom-color: currentColor;
            border-bottom-width: 2px;
            text-align: left;
            font-size: 24px;
            font-weight: $fw-black;
            color: inherit;

            &::after {
                display: none;
            }
        }
    }

    &-summary {
        list-style-type: none;

        &::-webkit-details-marker {
            display: none;
        }
    }

    &[open] & {
        &-title {
            background-color: $color-primary;

            svg {
                fill: currentColor;
            }
        }
    }

    &:not([open]) & {
        &-summary {
            height: 100%;
        }
    }

    @include media-breakpoint-up(md) {
        &:nth-of-type(even) & {
            &-content-wrapper {
                margin-left: calc(-100% - 2 * 15px);
            }
        }

        &-content-wrapper {
            max-width: calc(200% + 30px);
            width: calc(200% + 30px);
        }
    }

    @include media-breakpoint-down(md) {
        &-content {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    @include media-breakpoint-down(xs) {
        &-content {
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}
