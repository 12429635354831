.company {
    h2,
    .h2,
    h3,
    .h3 {
        text-align: left;

        &::after {
            display: none;
        }
    }

    h2,
    .h2 {
        margin-bottom: .5em;
        font-size: 40px;
    }

    h3,
    .h3 {
        margin-bottom: 1em;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: $color-primary;
        font-size: 26px;

        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    &-icons {
        text-align: center;
        font-size: 20px;

        & > * {
            &:not(:last-child) {
                border-right-width: 1px;
                border-right-style: solid;
                border-right-color: $color-primary;
            }
        }

        .picto-block {
            margin-top: 24px;
            margin-bottom: 24px;

            &-icon {
                margin-bottom: 24px;
            }
        }
    }

    &-arrows {
        text-align: center;

        .row {
            flex-wrap: nowrap;
        }
    }

    &-arrow {
        width: 42px;
        height: 42px;
        margin-top: 26px;
        margin-bottom: 26px;
        margin-left: auto;
        margin-right: auto;
        background-image: url(/system/modules/capital-conseil/assets/svg/arrow-right.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        &-circle {
            width: 94px;
            height: 94px;
            margin-bottom: 14px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            border-style: solid;
            border-width: 4px;
            border-color: $color-primary;
            line-height: 94px;
            font-size: 42px;
            font-weight: $fw-black;
            color: $color-text-lightest;

            svg {
                display: block;
                width: 44px;
                height: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            &.-target svg {
                transform: translate(6%, -3%);
            }

            &.-handshake-outline svg {
                width: 52px;
                transform: translateY(2%);
            }

            &.-group svg {
                transform: translateY(-4%);
            }
        }

        &-text {
            color: $color-secondary;
            font-weight: $fw-black;
        }
    }

    @include media-breakpoint-down(md) {
        &-icons {
            & > * {
                &:nth-child(even) {
                    border-right-width: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        &-arrow {
            transform: rotate(90deg);
        }
    }

    @include media-breakpoint-down(xs) {
        &-icons {
            & > * {
                &:nth-child(n) {
                    border-right-width: 0;
                }
            }

            .picto-block {
                &-icon {
                    margin-bottom: 12px;
                }
            }
        }
    }
}
