.learn-more {
    &-row {
        position: relative;
    }

    &-picture {
        display: block;
        width: 70%;
    }

    &-content {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        max-width: 640px;
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 48px;
        padding-right: 48px;
        background-color: rgba($color-bg-lightest, .85);
        text-align: center;
    }

    &-text {
        text-align: left;

        strong {
            font-weight: $fw-black;
            color: $color-secondary;
        }
    }

    @include media-breakpoint-up(lg) {
        &.u-full-width {
            padding-left: 120px;
            padding-right: 120px;
        }

        &.bg-lighter {
            .learn-more-content {
                left: 0;
                right: auto;
            }

            .learn-more-picture {
                margin-left: auto;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        &-picture {
            width: 100%;
            margin-bottom: -96px;
        }

        &-content {
            position: relative;
            top: auto;
            bottom: auto;
            left: auto;
            right: auto;
            transform: none;
            width: 640px;
            max-width: 90%;
            padding-top: 32px;
            padding-bottom: 32px;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
