@font-face {
    font-family: Roboto;
    src: url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Regular.woff2) format('woff2'),
        url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
    font-weight: $fw-regular;
}

@font-face {
    font-family: Roboto;
    src: url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Bold.woff2) format('woff2'),
        url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
    font-weight: $fw-bold;
}

@font-face {
    font-family: Roboto;
    src: url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Black.woff2) format('woff2'),
        url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Black.ttf) format('truetype');
    font-weight: $fw-black;
}

@font-face {
    font-family: Roboto;
    src: url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Medium.woff2) format('woff2'),
        url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
    font-weight: $fw-medium;
}

@font-face {
    font-family: Roboto;
    src: url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Light.woff2) format('woff2'),
        url(/system/modules/capital-conseil/assets/fonts/Roboto/Roboto-Light.ttf) format('truetype');
    font-weight: $fw-light;
}
