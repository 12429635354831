.block-contact {
    margin-bottom: 0;

    &-icon {
        padding-right: 1em;

        svg {
            display: block;
            width: 280px;
            height: auto;
        }
    }

    &-content {
        padding: 1em;
        border-left-width: 1px;
        border-left-style: solid;
        line-height: 1.2;
        font-size: 22px;

        p {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        &-icon {
            padding-right: 0;
        }

        &-content {
            margin-top: 1em;
            border-left-width: 0;
            border-top-width: 1px;
            border-top-style: solid;
        }
    }
}
