.slogan {
    $padding-top: 140px;
    $padding-bottom: 64px;

    display: flex;
    align-items: center;
    padding-top: $padding-top;
    padding-bottom: $padding-bottom;
    background-color: $color-primary;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &-title {
        margin-bottom: 0;
        margin-left: -400px;
        font-size: 64px;
        font-weight: $fw-black;
        color: $color-text-lightest;

        &::after {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        &.u-full-width {
            padding-left: 120px;
            padding-right: 120px;
        }
    }

    @include media-breakpoint-down(lg) {
        &.u-full-width {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    @include media-breakpoint-down(md) {
        &-title {
            font-size: 48px;
        }
    }

    @include media-breakpoint-down(sm) {
        position: relative;
        padding-bottom: $padding-top;

        &-icon {
            position: absolute;
            top: $padding-bottom;
            bottom: $padding-bottom;
            left: 24px;
            max-width: calc(100% - 2 * 24px);
            opacity: .4;

            svg {
                width: auto;
                height: 100%;
            }
        }

        &-title {
            position: relative;
            margin-left: 0;
        }
    }
}
