.scroll-to-top {
    position: fixed;
    z-index: 5000;
    bottom: 6px;
    right: 6px;
    padding: 18px;
    border-radius: 0;
    box-shadow: rgba(#000, .3) 0 3px 3px;
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0s, .25s;
    transition-delay: .25s, 0s;

    body.-scrolled.-up & {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    svg {
        display: block;
        width: 24px;
        height: 24px;
    }
}
