.testimonies {
    padding-left: 0;
    padding-right: 0;
    background-color: $color-bg-lighter;

    .carousel {
        padding-bottom: 36px;

        .container {
            width: calc(100% - 2 * 100px);
        }

        &-indicators {
            margin-bottom: 0;

            li {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #eae7e7;

                &.active {
                    background-color: $color-primary;
                }
            }
        }

        &-control-next,
        &-control-prev {
            z-index: auto;
            min-width: 100px;

            @each $breakpoint, $container-max-width in $container-max-widths {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                    width: calc((100vw - #{$container-max-width}) / 2);
                }
            }

            &-icon {
                width: 52px;
                height: 74px;
            }
        }

        &-control-next-icon {
            background-image: url(/system/modules/capital-conseil/assets/svg/chevron-carousel-next.svg);
        }

        &-control-prev-icon {
            background-image: url(/system/modules/capital-conseil/assets/svg/chevron-carousel-prev.svg);
        }
    }

    @include media-breakpoint-down(xs) {
        .carousel {
            .container {
                max-width: calc(100% - 2 * 50px);
            }

            &-control-next,
            &-control-prev {
                width: 50px;

                &-icon {
                    width: 26px;
                    height: 37px;
                }
            }
        }
    }
}

.testimony {
    &-picture {
        margin-bottom: 24px;
        border-radius: 50%;
    }

    &-name,
    &-job {
        text-align: left;

        &::after {
            display: none;
        }
    }

    &-name {
        color: $color-primary;
    }

    &-job {
        color: $color-text-lighter;
    }

    &-text {
        color: $color-text-lighter;
    }
}
