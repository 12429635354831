.team-mate {
    margin-top: 28px;
    margin-bottom: 28px;
    transition-property: opacity;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;

    &-picture {
        margin-bottom: 28px;
    }

    &-title {
        margin-bottom: 0;
        font-size: 28px;
        text-align: left;

        &::after {
            content: ",";
            display: inline;
            color: inherit;
        }
    }

    &-content {
        padding-left: 22px;
        border-left-width: 6px;
        border-left-style: solid;
        border-left-color: $color-primary;
    }

    &-text {
        font-weight: $fw-medium;

        p {
            margin-bottom: 0;
        }

        strong {
            color: $color-primary;
            font-size: 20px;
            font-weight: $fw-black;
        }
    }
}

.section.-team {
    padding-bottom: 68px;

    h2 {
        margin-bottom: 20px;
    }
}
