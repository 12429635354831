.form-contact {
    text-align: center;

    .formbody  {
        display: flex;
        flex-wrap: wrap;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }

    .widget {
        width: 100%;
        margin-bottom: 2em;
        padding-left: 1em;
        padding-right: 1em;
    }

    .widget-text,
    .widget-email,
    .widget-textarea {
        text-align: left;
    }

    .widget-checkbox {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-up(sm) {
        .widget-text,
        .widget-email {
            width: 50%;
        }
    }
}

body.-form-contact-upper {
    #footer .inside {
        display: flex;
        flex-direction: column;

        & > * {
            order: 2;
        }

        .form-contact {
            order: 1;
        }
    }
}
