.bg-dark,
.bg-dark-lighter,
.bg-primary,
.bg-secondary {
    color: $color-text-lightest;

    @include headings() {
        color: inherit;
    }
}

.strongest {
    font-weight: $fw-black;
    color: $color-secondary;
}
