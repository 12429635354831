.expertise {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 600px;
    padding-left: 0;
    padding-right: 0;
    background-color: $color-secondary;
    color: $color-text-lightest;

    &:nth-of-type(odd) {
        background-color: $color-primary;

        .expertise-content {
            order: 1;
        }

        .expertise-picture-wrapper {
            order: 2;
        }
    }

    &-picture-wrapper,
    &-content {
        width: 50%;
    }

    &-picture {
        width: 100%;
        height: 100%;
        align-self: stretch;
        object-fit: cover;
        object-position: center center;
        transition-property: transform;
        transition-duration: .4s;
        transition-timing-function: ease-in-out;

        &:hover {
            transform: scale(1.05);
        }

        &-wrapper {
            align-self: stretch;
            overflow: hidden;
        }
    }

    &-content {
        padding: 48px;
    }

    &-title,
    &-text {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    &-title {
        text-align: left;
        font-size: 40px;
        color: inherit;

        &::after {
            content: "";
            height: 1px;
            width: 100%;
            max-width: 500px;
            margin-top: .5em;
            margin-bottom: .5em;
            margin-left: 0;
            background-color: currentColor;
            color: inherit;
        }
    }

    @include media-breakpoint-down(sm) {
        &-picture-wrapper,
        &-content {
            width: 100%;

            .expertise:nth-of-type(odd) & {
                order: 0;
            }
        }

        &-content {
            padding-top: 64px;
        }
    }
}
