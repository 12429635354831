.news-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    &-title {
        margin-top: 0;
        margin-bottom: 1em;
        text-align: inherit;
        font-size: 30px;
        font-weight: $fw-black;
        color: $color-text-darker;

        a {
            color: inherit;
        }

        &::after {
            display: none;
        }
    }

    .image_container {
        overflow: hidden;

        img {
            transition-property: transform;
            transition-duration: .25s;
            transition-timing-function: ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    &-more {
        margin-top: auto;
        font-size: 24px;
        font-weight: $fw-black;

        &-link {
            text-decoration: underline;
        }
    }
}
