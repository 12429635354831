.section {
    padding-top: 96px;
    padding-bottom: 96px;

    &.-with-separator {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            height: 1px;
            width: 100%;
            max-width: 540px;
            background-color: $color-secondary;
        }
    }

    &.-team {
        &:hover {
            .team-mate {
                opacity: 0.6;
            }
        }

        .team-mate:hover {
            opacity: 1;
        }
    }

    &.-numbers {
        border-bottom-width: 50px;
        border-bottom-style: solid;
        border-bottom-color: $color-primary;
        text-align: center;

        .picto-block {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-top: 12px;
            padding-bottom: 12px;

            &-content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            &-headline {
                margin-top: auto;
                margin-bottom: auto;

                &::after {
                    display: none;
                }
            }

            &-subheadline {
                line-height: 1.1;
                font-size: 48px;
                font-weight: $fw-black;
            }
        }
    }

    @include media-breakpoint-down(md) {
        &.-team {
            padding-left: 24px;
            padding-right: 24px;
        }

        &.-numbers {
            padding-left: 24px;
            padding-right: 24px;

            .picto-block {
                &-subheadline {
                    font-size: 36px;
                }
            }
        }
    }
}
