@mixin headings() {
    $headings: '';
    @for $i from 1 through 6 {
        $headings: $headings + 'h#{$i}, .h#{$i}, ';
    }
    $headings: str-slice($headings, 0, -2);

    #{$headings} {
        @content;
    }
}
