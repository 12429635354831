.footer-nav {
    padding-top: 36px;
    padding-bottom: 42px;
    text-align: center;

    &-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &-items {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;
            padding-left: 0;
            list-style-type: none;

            li {
                &::after {
                    content: "|";
                    padding-left: 0.4em;
                    padding-right: 0.4em;
                }
            }
        }
    }

    a {
        color: inherit;

        &[target="_blank"] {
            font-weight: $fw-black;
            color: $color-text-darker;
        }
    }
}
