// -------------
// FONT-WEIGHTS
// -------------
$fw-black: 900;
$fw-bold: 700;
$fw-medium: 500;
$fw-regular: 400;
$fw-light: 300;

// -------------
// COLORS
// -------------
$color-bg-dark: #222222;
$color-bg-dark-lighter: #343434;

$color-bg-light: #f0f0f0;
$color-bg-lighter: #f5f5f5;
$color-bg-lighter-2: #f8f8f8;
$color-bg-lightest: #ffffff;

$color-primary: #d29b58;
$color-secondary: #005172;

$color-text: #8e8e8e;
$color-text-darker: #5a5a5a;
$color-text-darkest: #312c2c;
$color-text-lighter: #707070;
$color-text-lighter-2: #7b7b7b;
$color-text-lightest: #ffffff;

$color-placeholder: #95989a;

// Bootstrap override
$primary: $color-primary;
$secondary: $color-secondary;
$light: $color-bg-light;
$dark: $color-bg-dark;
$theme-colors: (
    'dark-lighter': $color-bg-dark-lighter,
    'lighter': $color-bg-lighter,
    'lighter-2': $color-bg-lighter-2,
    'lightest': $color-bg-lightest
);
