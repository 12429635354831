.quick-contact {
    $link-padding: 18px;
    $icon-width: 36px;
    $icon-margin: 12px;
    $link-shift: $link-padding + $icon-width + $icon-margin;

    position: fixed;
    right: $link-shift;
    top: 50%;
    visibility: hidden;
    opacity: 0;
    transition-property: visibility, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0s, .4s;
    transition-delay: .4s, 0s;

    body.-scrolled & {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    &.-phone {
        transform: translateX(100%) translateY(-87.5%);
    }

    &.-map {
        transform: translateX(100%) translateY(37.5%);
    }

    &-link {
        display: block;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: $link-padding;
        padding-right: $link-shift;
        border-radius: 24px;
        background-color: $color-bg-lightest;
        box-shadow: rgba(#000, .3) 0 3px 3px;
        white-space: nowrap;
        color: $color-secondary;
        font-size: 24px;
        font-weight: $fw-black;
        transition-property: transform;
        transition-duration: .2s;
        transition-timing-function: ease-out;

        &:focus {
            outline: none;
        }

        &.-active {
            transform: translateX(-100%) translateX($link-shift * 2 - $link-padding);
            color: $color-secondary;
            text-decoration: none;
        }

        &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: $icon-width;
            height: $icon-width;
            margin-right: $icon-margin;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &.-phone {
            &::before {
                background-image: url(/system/modules/capital-conseil/assets/svg/phone.svg);
            }
        }

        &.-map {
            &::before {
                background-image: url(/system/modules/capital-conseil/assets/svg/map-marker.svg);
            }
        }
    }
}
